import React, {useState, useEffect, useRef} from 'react';
import {Button, Input, Header, Dropdown, Icon } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalContent, ModalActions} from 'semantic-ui-react';
import './context.css';

export default function Context({setActiveItem}){
    const [personas, setPersonas] = useState("");
    const [product, setProduct] = useState("");
    const [business, setBusiness] = useState("");
    const [countPersonas, setCountPersonas] = useState(0);
    const [countBusiness, setCountBusiness] = useState(0);
    const [countProduct, setCountProduct] = useState(0);
    const [saving, setSaving] = useState(false);
    const [contexts, setContexts] = useState([]);
    const [selected, setSelected] = useState(-1);
    const [contextName, setContextName] = useState('');
    const [open, setOpen] = useState(false);
    const isInitialMount = useRef(true);

    const MAX_CHARS = 200;

    useEffect(()=>{
        var _function = 'Context useEffect';
        console.log(_function, 'Fetching account data...');

        if(isInitialMount.current){
            isInitialMount.current = false;

            let token = localStorage.getItem('token');

            fetch('/api/users/v1/getAllContexts', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    }
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(_function,'error when calling to retrieve contexts information');
                    throw new Error('Network response was not ok');
                }
                else{
                    console.log(_function, 'Contexts found and processed');
                    return response.json();
                }
            })
            .then(data =>{
                console.log(data);
                let context_results = data.result.map( o=> { return {key:o.id, value:o.id, text:o.name};})
                setContexts(context_results);
                setContextName(data.result.name);
            })
            .catch(error => {
                console.log(_function,'error:',error);
            })
        }
    },[]);

    const handlePersonaChange = (e) => {
        var _function = 'handlePersonaChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setPersonas(e.target.value);
            setCountPersonas(e.target.value.length);
        }

    }

    const handleProductChange = (e) => {
        var _function = 'handleProductChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setProduct(e.target.value);
            setCountProduct(e.target.value.length);
        }
    }

    const handleBusinessChange = (e) => {
        var _function = 'handleBusinessChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setBusiness(e.target.value);
            setCountBusiness(e.target.value.length);
        }
    }
    
    /**
     * Handles when user click on login
     * @param {*} e 
     */
    const handleSave = (e) =>{
        var _function = 'handleSave';
        console.log(_function, 'called');

        let body = {
            product:product,
            business:business,
            personas:personas,
            name:contextName,
            contextId:selected,
        }

        let token = localStorage.getItem('token');

        setSaving(true);

        fetch('/api/users/v1/updateContext', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log(_function, 'context successfully saved');
            setSaving(false);
        })
        .catch(error =>{
            console.log(_function, 'error while trying to save context');
        })
    }

    /**
     * When users make a selection with the dropdown, we trigger the retrievla of the information for that specific context.
     * @param {*} e 
     */
    const handleSelection = (e, data) => {
        var _function = 'handleSelection';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');
        let selection = data.value;

        fetch('/api/users/v1/getContext/'+selection, {
            method:'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                }
        })
        .then((response) => {
            if (!response.ok) {
                console.log(_function,'error when calling to retrieve contexts information');
                throw new Error('Network response was not ok');
            }
            else{
                console.log(_function, 'Contexts found and processed');
                return response.json();
            }
        })
        .then(data =>{
            console.log(data);
            setContextName(data.result.name);

            setPersonas(data.result.personas);
            setProduct(data.result.product);
            setBusiness(data.result.business);
            setCountProduct(data.result.product.length);
            setCountPersonas(data.result.personas.length);
            setCountBusiness(data.result.business.length);

            setSelected(selection);
        })
        .catch(error => {
            console.log(_function,'error:',error);
        })
    }

    /**
     * 
     * @param {*} e 
     */
    const handleAddContext = (e) => {
        var _function = 'handleAddContext';
        console.log(_function, 'called');

        setOpen(true);
    }


    /**
     * Called to handle the name of the solution
     * @param {*} e 
     */
    const handleNameChange = (e) => {
        var _function = 'handleNameChange';
        console.log(_function, 'called');

        setContextName(e.target.value);
    }

    /**
     * Called once the user clicked on the + THEN provided a name THEN clicked create
     * @param {*} e 
     */
    const handleNewContext = (e) => {
        var _function = 'handleNewContext';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');

        // Call API to create a new blank context but with the name provided
        let body = {
            product:'',
            business:'',
            personas:'',
            name:contextName,
        }

        fetch('/api/users/v1/newContext', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log(_function, 'context successfully created');
            return response.json();
        })
        .then(data => {
            console.log(_function, data);

            let new_context = data.context;
            // Add to the dropdown the new entry i.e. edit the list

            let contexts_copy = contexts.concat({key:new_context.id, value:new_context.id, text:new_context.name});
            setContexts(contexts_copy);

            // Set the selected value to the newest entry that just got created
            setSelected(new_context.id);

            // Set the inputs fields to blank
            setPersonas('');
            setProduct('');
            setBusiness('');
            setCountProduct(0);
            setCountPersonas(0);
            setCountBusiness(0);

            // Close the modal upon returning from the API
            setOpen(false);
        })
        .catch(error =>{
            console.log(_function, 'error while trying to save context');
        })
    }

    return <div>
        <div className="contextCenter">
            <span onClick={()=>setActiveItem('initiatives')} style={{ cursor: 'pointer', color: '#618FA8' }}>Back home</span>
            <div className="contextInputSection">
                <div className="contextHeader">
                    <Header as='h2' style={{color:'#1f496f'}} >Solution Contexts</Header>
                </div>
                <div className="contextNote">
                    Contexts enable you to increase the quality of the PRD output by providing additional context on the solution such as personas, value proposition and the product.
                </div>

                <div className='contextSelection'>
                    <div className="contextSelectionDropdown">
                        <Dropdown 
                            placeholder = {contexts.length === 0 ? 'Create a contexte' : 'Select a context'}
                            selection
                            fluid
                            options = {contexts}
                            onChange={handleSelection}
                            disabled={contexts.length === 0}
                            value={selected}
                        />
                    </div>

                    <div className="contextSelectionButton">
                        <Button icon onClick={handleAddContext}>
                            <Icon name='add' />
                        </Button>
                    </div>
                </div>

                <hr />

                <div style={{display:selected >= 0 ? '' : 'none'}}>

                    <hr style={{borderColor:'lightgrey', borderWidth:'0.05px', marginBottom:'20px'}} />

                    <div className="contextSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Solution</Header>
                    </div>

                    <div className="contextInstructions">
                        Describe the value proposition of your solution and its economic drivers
                    </div>
                    <div className='initInput'>
                        <Input fluid placeholder="Describe the value proposition of your business and its economic drivers" value={business} onChange={handleBusinessChange} input={{ style: { borderRadius: '10px' } }}  />
                        <div className='initCounter'>
                            {countBusiness}/{MAX_CHARS}
                        </div>
                    </div>

                    <div className="contextSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Personas</Header>
                    </div>                
                    <div className="contextInstructions">
                        Describe the users of your products, their roles and their objectives
                    </div>
                    <div className='initInput'>
                        <Input fluid placeholder="Describe the users of your products, their roles and their objectives" value={personas} onChange={handlePersonaChange} input={{ style: { borderRadius: '10px' } }} />
                        <div className='initCounter'>
                            {countPersonas}/{MAX_CHARS}
                        </div>                        
                    </div>

                    <div className="contextSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Product</Header>
                    </div>                
                    <div className="contextInstructions">
                        Describe your product and its key capabilities
                    </div>
                    <div className='contextInput'>
                        <Input fluid placeholder="Describe your product and its key capabilities" value={product} onChange={handleProductChange} input={{ style: { borderRadius: '10px' } }} />
                        <div className='initCounter'>
                            {countProduct}/{MAX_CHARS}
                        </div>                        
                    </div>
                </div>
            </div>

            <div className="contextButton">
                <Button primary onClick={handleSave} loading={saving}>Save</Button>
            </div>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size='small'
            >
                <ModalHeader>
                    Create a new Solution context
                </ModalHeader>

                <ModalContent>
                    <div className="contextName">
                        <div className="contextNameLabel">
                            Solution name
                        </div>
                        <div className="contextNameInput">
                            <Input fluid placeholder="Solution name" value={contextName} onChange={handleNameChange} input={{ style: { borderRadius: '10px' } }}  />
                        </div>
                    </div>
                </ModalContent>

                <ModalActions>
                    <Button onClick={ ()=>setOpen(false)}>Cancel</Button>
                    <Button onClick={handleNewContext} primary>Create</Button>
                </ModalActions>
            </Modal>
        </div>
    </div>
}